import { graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import React, { useState } from "react";
import Badge from "../../components/Badge";
import Container from "../../components/Container";
import Layout from "../../components/Layout";
import LinkButton from "../../components/LinkButton";
import PostTags from "../../components/PostTags";
import Seo from "../../components/Seo";

export const query = graphql`
    query ($language: String!) {
        locales: allLocale(
            filter: { ns: { in: ["common"] }, language: { eq: $language } }
        ) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        allMarkdownRemark(sort: { fields: frontmatter___date, order: DESC }) {
            edges {
                node {
                    frontmatter {
                        title
                        date(formatString: "YYYY-MM-DD")
                        author
                        description
                        slug
                        tags
                        image {
                            childImageSharp {
                                gatsbyImageData(
                                    layout: FULL_WIDTH
                                    transformOptions: { fit: COVER }
                                )
                            }
                            relativePath
                        }
                    }
                }
            }
        }
    }
`;

export default function Blog({
    data: {
        allMarkdownRemark: { edges: allPosts },
    },
}) {
    const allTags = allPosts
        .reduce((tags, post) => tags.concat(post.node.frontmatter.tags), [])
        .filter((tag) => tag !== null)
        .filter((tag, index, tags) => tags.indexOf(tag) === index);

    const [displayedTags, setDisplayedTags] = useState(allTags);

    const updateDisplayedTags = (clickedTag) => {
        // If previously all tags are displayed, show only the clicked one
        if (displayedTags.length === allTags.length) {
            setDisplayedTags([clickedTag]);
            return;
        }

        // Toggle the clicked tag
        const newTagsToDisplay = displayedTags;
        const index = newTagsToDisplay.indexOf(clickedTag);
        index === -1
            ? newTagsToDisplay.push(clickedTag)
            : newTagsToDisplay.splice(index, 1);

        // If the displayed tags list will be emtpy by this action, show all tags.
        if (newTagsToDisplay.length === 0) {
            setDisplayedTags([...allTags]);
            return;
        }

        setDisplayedTags([...newTagsToDisplay]);
    };

    return (
        <Layout>
            <Seo title="News" />
            <Container>
                <div className="mb-4">
                    <b className="pr-2">Categorie:</b>
                    {allTags.map((tag, index) => (
                        <Badge
                            key={index}
                            title={tag}
                            isActive={displayedTags.indexOf(tag) !== -1}
                            clickHandler={() => updateDisplayedTags(tag)}
                        />
                    ))}
                </div>
                {allPosts.map(({ node: { frontmatter: post } }, index) => {
                    if (allTags.length !== displayedTags.length) {
                        if (post.tags === null) {
                            return null;
                        }

                        if (
                            post.tags.filter(
                                (tag) => displayedTags.indexOf(tag) !== -1
                            ).length === 0
                        ) {
                            return null;
                        }
                    }

                    return (
                        <div
                            key={index}
                            className="grid grid-cols-3 mb-6 bg-white rounded-xl shadow-darkBrown shadow-md"
                        >
                            <div className="col-span-3 md:col-span-1">
                                <GatsbyImage
                                    image={
                                        post.image.childImageSharp
                                            .gatsbyImageData
                                    }
                                    alt="cover-articolo"
                                    className="h-full rounded-xl"
                                />
                            </div>
                            <div className="col-span-3 md:col-span-2 md:col-start-2 p-4">
                                <h2>{post.title}</h2>
                                <small>
                                    Pubblicato il <b>{post.date}</b> da{" "}
                                    <b>
                                        <i>{post.author}</i>
                                    </b>
                                </small>
                                <PostTags tags={post.tags} />
                                <p className="mt-2">{post.description}</p>
                                <div className="text-right">
                                    <LinkButton to={post.slug} text="Leggi" />
                                </div>
                            </div>
                        </div>
                    );
                })}
            </Container>
        </Layout>
    );
}
