import { Link } from "gatsby-plugin-react-i18next";
import React from "react";
import Button from "./Button";

const LinkButton = ({ to, text }) => {
    return (
        <Link to={to}>
            <Button text={text} />
        </Link>
    );
};

export default LinkButton;
